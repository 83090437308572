<template>
  <div class="content">
    <!-- 大图片 -->
    <div class="group_1 flex-col">
      <div class="box_1 flex-col">
        <span class="text_10">标杆案例</span>
        <span class="text_11">建筑能源领域，数字化转型成功案例</span>
        <div class="group_3 flex-col"></div>
      </div>
    </div>
    <div class="service_body">
      <div class="self_card" id="syzht">
        <div class="card_title_box">
          <div class="card_title">商业综合体</div>
        </div>
        <div class="card_body">
          <div
            class="service_example"
            v-for="(item, index) in syzhtList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_cate">商业综合体</div>
            <div class="service_title">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="self_card" id="jyyl">
        <div class="card_title_box">
          <div class="card_title">教育医疗</div>
        </div>
        <div class="card_body">
          <div
            class="service_example"
            v-for="(item, index) in jyylList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_cate">教育医疗</div>

            <div class="service_title">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="self_card" id="zhyq">
        <div class="card_title_box">
          <div class="card_title">智慧园区</div>
        </div>
        <div class="card_body">
          <div
            class="service_example"
            v-for="(item, index) in zhyqList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_cate">智慧园区</div>

            <div class="service_title">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="self_card" id="jdgy">
        <div class="card_title_box">
          <div class="card_title">酒店公寓</div>
        </div>
        <div class="card_body">
          <div
            class="service_example"
            v-for="(item, index) in jdgyList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_cate">酒店公寓</div>

            <div class="service_title">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="self_card" id="gkqy">
        <div class="card_title_box">
          <div class="card_title">工矿企业</div>
        </div>
        <div class="card_body">
          <div
            class="service_example"
            v-for="(item, index) in gkqyList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_cate">工矿企业</div>

            <div class="service_title">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="self_card" id="gfcn">
        <div class="card_title_box">
          <div class="card_title">光伏储能</div>
        </div>
        <div class="card_body">
          <!-- <el-empty
            style="width: 100%; height: 100%"
            description="暂无案列"
          ></el-empty> -->
          <div
            class="service_example"
            v-for="(item, index) in gfcnList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_cate">光伏储能</div>
            <div class="service_title">{{ item.label }}</div>
          </div>
          <!-- <div
            class="service_example"
            v-for="(item, index) in gkqyList"
            :key="index"
          >
            <div class="service_img">
              <img :src="item.value" alt="" />
            </div>
            <div class="service_title">{{ item.label }}</div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 左侧悬浮快速定位框 -->
    <div class="local_cls" id="zcxfk">
      <div class="local_item" @click="goId('syzht')">商业综合体</div>
      <div class="local_item" @click="goId('jyyl')">教育医疗</div>
      <div class="local_item" @click="goId('zhyq')">智慧园区</div>
      <div class="local_item" @click="goId('jdgy')">酒店公寓</div>
      <div class="local_item" @click="goId('gkqy')">工矿企业</div>
      <div class="local_item" @click="goId('gfcn')">光伏储能</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      syzhtList: [],
      gkqyList: [],
      jyylList: [],
      jdgyList: [],
      zhyqList: [],
      gfcnList: [],
      gfcnList: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.zcxfkListener);
    this.getIndexInfo();
  },
  destroyed() {
    window.removeEventListener("scroll", this.zcxfkListener);
  },
  methods: {
    goId(val) {
      document.querySelector("#" + val).scrollIntoView(true);
    },
    async getIndexInfo() {
      const res1 = await this.axios.get("/jsondata/fwallist.json");
      this.syzhtList = res1.data.syzht;
      this.gkqyList = res1.data.gkqy;
      this.jyylList = res1.data.jyyl;
      this.jdgyList = res1.data.jdgy;
      this.zhyqList = res1.data.zhyq;
      this.gfcnList = res1.data.gfcn;
    },
    zcxfkListener() {
      let dom = document.querySelectorAll(".local_cls");
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 100) {
        dom[0].style.visibility = "visible";
        dom[0].style.opacity = "1";
        dom[0].style.transition = "all 0.5s ease";
      }
      if (scrollTop < 100) {
        dom[0].style.visibility = "hidden";
        dom[0].style.opacity = "0";
        dom[0].style.transition = "all 0.5s ease";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}
.toptu {
  width: 100%;
  height: 15.625vw;
  background: no-repeat top left;
  background-size: 100% 100%;
  position: relative;
  background-image: url(../assets/image/gcalbg.jpg);
  margin-top: 4.79vw;
  .page_title {
    position: absolute;
    font-size: 2.29vw;
    letter-spacing: 0.26vw;
    color: #ffffff;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.service_body {
  width: 80vw;
  margin: 0 auto;
  .self_card {
    background-color: #fff;
    padding: 1rem 3rem;
    border-bottom: 1px solid #ccc;
    border-radius: 6px;
    margin: 1rem 0;
    .card_title_box {
      width: 100%;
      height: 5rem;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1.5rem;
      .card_title {
        display: inline-block;
        height: 4.8rem;
        line-height: 4.8rem;
        border-bottom: 0.21vw solid #0a79d4;
        font-size: 1.875vw;
      }
    }

    .card_body {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .service_example {
        // width: 24%;
        width: 17.5vw;
        margin-right: 0.625vw;
        margin-bottom: 1vw;
        box-shadow: 0.5vw 0.1vw 0.78125vw 0px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(244, 244, 244, 1);

        .service_img {
          width: 17.5vw;
          height: 12.9vw;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .service_cate {
          font-size: 0.83vw;
          color: #3439c0;
          font-family: AlibabaPuHuiTi-Regular;
          line-height: 1.15vw;
          margin-left: 1.25vw;
          margin-top: 1.04vw;
        }

        .service_title {
          // text-align: center;
          font-size: 1.04vw;
          font-family: AlibabaPuHuiTi-Regular;
          color: #434343;
          line-height: 1.41vw;
          margin-top: 0.42vw;
          margin-left: 1.25vw;
          margin-bottom: 1.04vw;
        }
      }
      // .service_example:nth-child(4n) {
      //   margin-right: 0;
      // }
    }
  }
}

.local_cls {
  visibility: hidden;
  width: 4.68vw;
  position: fixed;
  left: 3%;
  top: 50%;
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0, 0, 0, 10%);
  padding: 0.3125vw 0;
  border-radius: 4px;
  transform: translate(0, -50%);
  .local_item {
    font-family: "Poppins", "PingFang SC", "微软雅黑", sans-serif;
    font-weight: 400;
    word-break: normal;
    word-wrap: normal;
    font-size: 0.67vw;
    line-height: 1.6666;
    padding: 0.41vw;
    color: #2a3035;
    cursor: pointer;
  }
  .local_item:hover {
    background-color: rgba(39, 78, 187, 0.1);
    border-left: 4px solid #007bff;
    color: #007bff;
  }
}
// 新版css
.group_1 {
  background-image: url(../assets/service/servicebg1.png);
  height: 24.9vw;
  border: 1px solid rgba(151, 151, 151, 1);
  width: 100vw;
}

.box_1 {
  // background-color: rgba(24, 59, 124, 0.55);
  width: 100vw;
  height: 24.9vw;
}
.text_10 {
  width: 6.67vw;
  height: 2.35vw;
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 1.66vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.35vw;
  margin: 8.32vw 0 0 12.5vw;
}

.text_11 {
  width: 37.77vw;
  height: 3.29vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.39vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 3.29vw;
  margin: 0.36vw 0 0 12.5vw;
}

.group_3 {
  background-color: rgba(255, 123, 52, 1);
  width: 9.38vw;
  height: 0.21vw;
  margin: 1.35vw 0 8.33vw 12.5vw;
}
</style>
