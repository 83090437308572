<template>
  <div class="box">
    <div class="cards_box">
      <div
        class="device_card"
        v-for="(item, index) in productList"
        :key="index"
      >
        <div class="device_img_cls">
          <img :src="item.imgurl" alt="" />
        </div>
        <div class="device_msg">
          <div class="device_name">{{ item.label }}</div>
          <div class="device_des">
            {{ item.des }}
          </div>
          <div class="learn_more" @click="goDetailPage(item.value)">
            了解更多 >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    curFatherType: String,
    curSonType: String,
  },
  data() {
    return {
      // productList: [],
      devicesList1: [],
      devicesList2: [],
      devicesList3: [],
      softwareList1: [],
      softwareList2: [],
      softwareList3: [],
      softwareList4: [],
    };
  },
  computed: {
    productList() {
      if (this.curSonType == "yjall") {
        return this.devicesList1.concat(this.devicesList2, this.devicesList3);
      } else if (this.curSonType == "rjall") {
        return [
          ...this.softwareList1,
          ...this.softwareList2,
          ...this.softwareList3,
          ...this.softwareList4,
        ];
      } else if (this.curSonType == "yjdlyb") {
        return this.devicesList1;
      } else if (this.curSonType == "yjsnkqcgq") {
        return this.devicesList2;
      } else if (this.curSonType == "yjtxsb") {
        return this.devicesList3;
      } else if (this.curSonType == "rjnhgl") {
        return this.softwareList1;
      } else if (this.curSonType == "rjdljk") {
        return this.softwareList2;
      } else if (this.curSonType == "rjhjjc") {
        return this.softwareList3;
      } else if (this.curSonType == "rjlkjzm") {
        return this.softwareList4;
      }
    },
  },
  mounted() {
    this.getIndexInfo();
  },
  methods: {
    async getIndexInfo() {
      const res1 = await this.axios.get("/jsondata/yjdlyb.json");
      const res2 = await this.axios.get("/jsondata/yjhjjc.json");
      const res3 = await this.axios.get("/jsondata/yjtxsb.json");
      const res4 = await this.axios.get("/jsondata/rjnhgl.json");
      const res5 = await this.axios.get("/jsondata/rjdljk.json");
      const res6 = await this.axios.get("/jsondata/rjhjjc.json");
      const res7 = await this.axios.get("/jsondata/rjlkjzm.json");
      this.devicesList1 = res1.data.list;
      this.devicesList2 = res2.data.list;
      this.devicesList3 = res3.data.list;
      this.softwareList1 = res4.data.list;
      this.softwareList2 = res5.data.list;
      this.softwareList3 = res6.data.list;
      this.softwareList4 = res7.data.list;
    },
    goDetailPage(deviceid) {
      window.sessionStorage.setItem("curcateinSession", this.curFatherType);
      window.sessionStorage.setItem("cursonValueinSession", this.curSonType);
      if (this.curFatherType == "nyszhjjfa") {
        this.$router.push({
          name: "SoftwareDetail",
          params: {
            deviceid,
          },
        });
      } else {
        this.$router.push({
          name: "ProductDetail",
          params: {
            deviceid,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 75vw;
  margin: 0 auto;
  .title {
    font-size: 2.0833vw;
    text-align: center;
    font-weight: bold;
    color: #222;
  }
  .des {
    font-size: 0.8333vw;
    line-height: 1.5625vw;
    margin: 1.0417vw 0px;
    text-align: left;
    font-family: "微软雅黑";
    color: #333;
  }
  .item_son {
    display: flex;
    flex-wrap: wrap;
    .son_type {
      width: 15%;
      padding-left: 1.3021vw;
      margin-right: 1.0417vw;
      margin-top: 0.7813vw;
      border: solid 1px #e3e3e3;
      border-radius: 0.5208vw;
      font-size: 0.7292vw;
      text-align: left;
      background: #fff;
      color: #333333;
      line-height: 1.9792vw;
      box-shadow: 0.1042vw 0.1042vw 0.2604vw #e5e5e5;
      transition: all 0.5s ease;
      cursor: pointer;
    }
    .son_type:hover {
      color: #fff !important;
      background-color: #3fb8bf;
    }
  }

  .cards_box {
    width: 100%;
    // margin: 3% auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .device_card {
      width: 48%;
      height: 18.75vw;
      //   background-color: #ccc;
      margin-bottom: 1.5625vw;
      text-align: center;
      background-color: #fff;
      box-sizing: border-box;
      padding: 1.5625vw 1.5625vw;
      min-height: 15.625vw;
      display: flex;
      align-items: center;
      box-shadow: 0px 0.1042vw 0.7813vw 0px rgba(0, 0, 0, 0.05);
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0.2083vw;
      border: 1px solid rgba(244, 244, 244, 1);
      .device_img_cls {
        width: 15.625vw;
        height: 11.7188vw;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .device_msg {
        width: 15.625vw;
        margin-left: 1.0417vw;
        .device_name {
          font-size: 1.1458vw;
          text-align: left;
          border-bottom: solid 1px #e1e1e1;
          padding-bottom: 1.0417vw;
          font-weight: 700;
        }
        .device_des {
          font-size: 0.7292vw;
          line-height: 1.3021vw;
          letter-spacing: 1px;
          text-align: left;
          color: #959595;
          margin-top: 1.0417vw;
          height: 3.9063vw;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .learn_more {
          margin: 0 auto;
          width: 5.2083vw;
          height: 1.875vw;
          border: solid 1px #3fb8bf;
          text-align: center;
          line-height: 1.875vw;
          font-size: 0.7292vw;
          letter-spacing: 1px;
          color: #3fb8bf;
          border-radius: 0.5208vw;
          margin-top: 1.5625vw;
          transition: all 0.5s ease;
          cursor: pointer;
        }
      }
    }
  }
}
</style>