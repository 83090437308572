<template>
  <div class="box">
    <div class="product_banner">
      <div class="content_box">
        <div class="content_left">
          <div class="product_title">
            {{ deviceInfo.enname || "PWT-AQ-MFS" }}
            <br />
            {{ deviceInfo.name || "多功能空气质量传感器" }}
          </div>
          <div class="product_feature">
            {{
              deviceInfo.features ||
              "多合一监测｜高实用性｜易安装、接入灵活｜数据可视化"
            }}
          </div>
        </div>
        <div class="content_right">
          <!-- <div class="product_img_box"></div> -->
          <el-carousel trigger="click" height="20.9896vw">
            <el-carousel-item
              v-for="(item, index) in deviceInfo.lunImgs"
              :key="index"
            >
              <img loading="lazy" class="imgcls" :src="item.value" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div
      class="feature_box"
      v-for="(item, index) in deviceInfo.featureList"
      :key="index"
      :style="{ 'background-image': 'url(' + item.bgurl + ') !important' }"
    >
      <div class="feature_content" :class="index % 2 == 1 ? 'reverse' : ''">
        <div class="feature_left">
          <div class="feature_title">{{ item.label }}</div>
          <div class="hg"></div>
          <div
            class="feature_des"
            :style="{ color: index % 2 == 1 ? '#fff' : '#666' }"
          >
            {{ item.des }}
          </div>
        </div>
        <div class="feature_right">
          <img v-show="item.imgurl" :src="item.imgurl" alt="" />
        </div>
      </div>
    </div>
    <div class="tabs_box">
      <div class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane
            lazy
            :label="item.label"
            :name="index + ''"
            v-for="(item, index) in deviceInfo.tabImgs"
            :key="index"
          >
            <img :src="item.imgurl" alt="" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 悬浮 -->
    <!-- <div class="fix_box1">
      <div class="fix_text">
        <div class="fix_cls" @click="back()">
          <span>&lt;&nbsp; 返回</span>
        </div>
        <div
          class="fix_item"
          v-for="item in product_cate"
          :key="item.value"
          @click="backToProduct(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "0",
      product_cate: [
        {
          label: "软件产品",
          value: "nyszhjjfa",
        },
        {
          label: "硬件产品",
          value: "nywlwyjcp",
        },
      ],
      curdeviceVal: "",
      deviceInfo: {
        lunImgs: [],
      },
    };
  },
  mounted() {
    this.curdeviceVal = this.$route.params.deviceid;
    this.observeDoms(".feature_left");
    this.getDeviceInfo();
  },
  destroyed() {
    this.removeObserve(".feature_left");
  },
  methods: {
    async getDeviceInfo() {
      const res1 = await this.axios.get(
        "/jsondata/detaildata/" + this.curdeviceVal + ".json"
      );
      this.deviceInfo = res1.data;
    },
    backToProduct(value) {
      this.$router.push({
        name: "Products",
        params: {
          curFatherType: value,
          curSonType: value == "nyszhjjfa" ? "rjall" : "yjall",
        },
      });
    },
    back() {
      history.back();
      // this.$router.push({
      //   name: "Products",
      //   params: {
      //     cateid: value,
      //   },
      // });
    },
    removeObserve(selector) {
      let doms = document.querySelectorAll(selector);
      doms.forEach((dom) => {
        this.observer.unobserve(dom);
        this.observer.disconnent();
      });
    },
    observeDoms(selector) {
      let doms = document.querySelectorAll(selector);
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.style.opacity = 1;
          entries[0].target.style.transition = "0.5s";
          entries[0].target.style.transform = "translateY(0px)";
        }
      });
      doms.forEach((dom) => {
        this.observer.observe(dom);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}

.product_banner {
  width: 100%;
  height: 28.645vw;
  background-image: url(../assets/image/test.png);
  background-size: cover;
  .content_box {
    display: flex;
    justify-content: space-between;
    height: 28.645vw;
    width: 70%;
    margin: auto;
    .content_left {
      margin-top: 11.19vw;
      .product_title {
        font-size: 2.5vw;
        font-weight: 400;
        color: #2d2d2d;
        line-height: 1.3;
        margin: 0 0 1.0417vw 0;
      }
      .product_feature {
        font-size: 1.0417vw;
        font-weight: normal;
        color: #666;
      }
    }
    .content_right {
      width: 23.333vw;
      height: 20.98vw;
      align-self: flex-end;
      overflow: hidden;
      position: relative;
      .imgcls {
        width: 23.4375vw;
        height: 18.75vw;
      }
    }
  }
}
::v-deep .el-carousel__button {
  background-color: #000 !important;
  margin-top: -3.125vw;
}

.feature_box {
  width: 100vw;
  height: 27.0833vw;
  background-repeat: no-repeat;
  background-size: cover;
  // background-color: #fff;
  .feature_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 27.0833vw;
    width: 70%;
    margin: auto;

    .feature_left {
      // transform: translateY(5.2083vw);
      .feature_title {
        font-size: 1.5625vw;
        margin-bottom: 0.4167vw;
      }
      .hg {
        height: 0.3125vw;
        width: 1.5625vw;
        background-color: #0a7ad1;
        margin-bottom: 1.0938vw;
      }
      .feature_des {
        width: 16.666vw;
        font-size: 1.0417vw;
        font-weight: lighter;
        line-height: 150%;
        font-family: "Open Sans" !important;
        color: #666;
      }
    }

    .feature_right {
      max-height: 100%;
      max-width: 33.8542vw;
      max-height: 26.0417vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.reverse {
  flex-direction: row-reverse;
  color: #fff;
  // background: #fff !important;
}

.tabs_box {
  width: 100%;
  background-color: #fff;
  padding: 1.5625vw 0;
  .tabs {
    width: 80%;
    margin: 0 auto;
  }
}
::v-deep .el-tabs__item {
  font-size: 1.1458vw;
}
::v-deep .el-tabs__nav-scroll {
}

::v-deep .el-tabs__content img {
  max-width: 100%;
}

// 悬浮返回
.fix_box1 {
  display: flex;
  position: fixed;
  top: 5.7292vw;
  z-index: 999;
  cursor: pointer;
  transition: 0.2s;
}
.fix_box1:hover {
  left: 0;
  transition: 0.2s;
}
.fix_cls {
  width: 5.2083vw;
  height: 1.875vw;
  line-height: 1.875vw;
  text-align: center;
  font-size: 0.625vw;
}
.fix_cls:hover {
  background-color: #fff;
  color: #004899;
}
.fix_text {
  width: 5.2083vw;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4) !important;
  font-size: 0.6771vw;
  color: #fff;
  transition: all 0.2s;
  .fix_item {
    height: 1.875vw;
    line-height: 1.875vw;
    text-align: center;
    border-top: 1px solid #ccc;
  }
  .fix_item:hover {
    background-color: #fff;
    color: #004899;
  }
}
</style>