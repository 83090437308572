<template>
  <div class="content">
    <!-- 大图片 -->
    <div class="group_1 flex-col">
      <div class="section_1 flex-col">
        <span class="text_10">产品中心</span>
        <span class="text_11"
          >建筑能源管理领域解决方案&nbsp;，建筑数字化孪生平台提供商</span
        >
        <div class="group_3 flex-col"></div>
      </div>
    </div>
    <div class="tabs_box">
      <div class="tabs_soft">
        <div
          class="tab_item_box"
          v-for="(item, index) in product_cates"
          :key="index"
          @click="changeCate(item.value)"
        >
          <div
            class="soft_item_box"
            :class="curFatherType == item.value ? '' : 'no_selected'"
          >
            <div class="soft_name">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="sontype_list_box">
        <div
          class="sontype_item_box"
          :class="curSonType == item.value ? '' : 'no_selected'"
          @click="chooseSontype(item.value)"
          v-for="item in cursontypeList"
          :key="item.value"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <Category
      :curFatherType="curFatherType"
      :curSonType="curSonType"
    ></Category>
  </div>
</template>

<script>
import Category from "./productCom/category.vue";
export default {
  components: {
    Category,
  },
  data() {
    return {
      curFatherType: "nyszhjjfa",
      curSonType: "rjall",
      product_cates: [
        {
          label: "能源数字化解决方案",
          value: "nyszhjjfa",
          children: [
            {
              label: "全部",
              value: "rjall",
            },
            {
              label: "能耗管理",
              value: "rjnhgl",
            },
            {
              label: "电力监控",
              value: "rjdljk",
            },
            {
              label: "环境监测",
              value: "rjhjjc",
            },
            {
              label: "楼控及照明",
              value: "rjlkjzm",
            },
          ],
        },
        {
          label: "能源物联网硬件产品",
          value: "nywlwyjcp",
          children: [
            {
              label: "全部",
              value: "yjall",
            },
            {
              label: "电力仪表",
              value: "yjdlyb",
            },
            {
              label: "室内空气传感器",
              value: "yjsnkqcgq",
            },
            {
              label: "通讯设备",
              value: "yjtxsb",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.params) != "{}") {
      let tempfathertype = sessionStorage.getItem("curFatherType");
      let tempsontype = sessionStorage.getItem("curSonType");
      this.curFatherType = tempfathertype || this.$route.params.curFatherType;
      this.curSonType = tempsontype || this.$route.params.curSonType;
      sessionStorage.removeItem("curFatherType");
      sessionStorage.removeItem("curSonType");
      this.$forceUpdate();
    }
    // window.addEventListener("scroll", this.productScrollListener);
    // if (this.$route.params.cateid) {
    //   this.curFatherType = this.$route.params.cateid;
    //   let temp = this.deviceTreeData.filter(
    //     (item) => item.value == this.curFatherType
    //   );
    //   this.curSonType = temp[0].children[0].value;
    // } else if (
    //   sessionStorage.getItem("previousPath").includes("detail") &&
    //   sessionStorage.getItem("curcateinSession")
    // ) {
    //   this.curFatherType = sessionStorage.getItem("curcateinSession") + "";
    //   this.curSonType = sessionStorage.getItem("cursonValueinSession");
    //   sessionStorage.removeItem("curcateinSession");
    //   sessionStorage.removeItem("cursonValueinSession");
    // } else {
    //   this.curFatherType = this.deviceTreeData[0].value;
    //   this.curSonType = this.deviceTreeData[0].children[0].value;
    // }
  },
  destroyed() {
    // sessionStorage.removeItem("curSonType");
    window.removeEventListener("scroll", this.productScrollListener);
  },
  computed: {
    cursontypeList() {
      return this.product_cates.filter(
        (item) => item.value == this.curFatherType
      )[0].children;
    },
  },
  methods: {
    productScrollListener() {
      let dom = document.querySelectorAll(".tabs_box");
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop >= 400) {
        dom[0].style.position = "fixed";
        dom[0].style.left = "0";
        dom[0].style.top = "4.7917vw";
        dom[0].style["z-index"] = "1230";
        dom[0].style["margin-top"] = "0px";
      }
      if (scrollTop < 400) {
        dom[0].style.position = "relative";
        dom[0].style["margin-top"] = "-3.125vw";
        dom[0].style.left = "";
        dom[0].style.top = "";
      }
    },
    // async getIndexInfo() {
    //   const res = await this.axios.get("/jsondata/yjdlyb.json");
    //   this.devicesList = res.data.list;
    // },
    changeCate(value) {
      this.curFatherType = value;
      if (this.curFatherType == "nyszhjjfa") {
        this.curSonType = "rjall";
        sessionStorage.setItem("curSonType", "rjall");
      } else {
        this.curSonType = "yjall";
        sessionStorage.setItem("curSonType", "yjall");
      }
      sessionStorage.setItem("curFatherType", value);
    },
    chooseSontype(val) {
      this.curSonType = val;
      sessionStorage.setItem("curSonType", val);
      sessionStorage.setItem("curFatherType", this.curFatherType);
      // this.$route.params.curSonType = val;
      // this.$router.push({
      //   name: "Products",
      //   params: {
      //     curFatherType: this.curFatherType,
      //     curSonType: val,
      //   },
      //   replace: true,
      // });
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}
.toptu {
  width: 100%;
  height: 20.8333vw;
  background: no-repeat top left;
  background-size: 100% 100%;
  position: relative;
  background-image: url(../assets/products/product_banner2.png);
  .page_title {
    position: absolute;
    font-size: 2.2917vw;
    letter-spacing: 0.2604vw;
    color: #ffffff;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.products {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.gscphanzi {
  text-align: center;
  font-size: 3.125vw;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2b2b2b;
  line-height: 1.1979vw;
  margin: 6.667vw auto 4.8438vw auto;
  z-index: 999;
}
.gscp {
  position: absolute;
  top: 5.3125vw;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.125vw;
  font-family: Helvetica;
  font-weight: bold;
  color: #cccccc;
  line-height: 1.1979vw;
  opacity: 0.6;
  z-index: 1;
}
.proall {
  width: 82.8125vw;
  margin: 4.8958vw auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.probox {
  width: 82.8125vw;
  height: 15.625vw;
  background: #ffffff;
  box-shadow: 0px 0px 0.2604vw 0px rgba(204, 204, 204, 0.6);
  border-radius: 0.7813vw;
  box-sizing: border-box;
  margin-bottom: 3.6458vw;
}
.proboxrow1 {
  display: flex;
  align-items: center;
  padding: 1.7188vw 3.8542vw;
}
.proboxrow1 > img {
  width: 2.0833vw;
  height: 2.0833vw;
}
.proboxrow1 > span {
  padding-left: 0.625vw;
  font-size: 1.25vw;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2b2b2b;
  line-height: 1.1979vw;
}
.proboxrow2 {
  padding: 0 3.9063vw;
  font-size: 1.0417vw;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #2b2b2b;
  word-wrap: break-word;
  line-height: 1.5625vw;
  margin-top: 1.25vw;
}

// 三层展示
::v-deep .el-tabs__item.is-active {
  color: #fff;
}
::v-deep .el-tabs__item {
  color: #e1e1e1;
  font-size: 0.8333vw;
}

.device_cls {
  min-width: 14%;
  padding: 0 1.3021vw;
  margin-bottom: 0.7813vw;
  margin-right: 1.0417vw;
  border: solid 1px #e3e3e3;
  border-radius: 0.5208vw;
  font-size: 0.7292vw;
  text-align: left;
  background: #fff;
  color: #333333;
  height: 1.9792vw;
  line-height: 1.9792vw;
  transition: all 0.5s ease;
  cursor: pointer;
}
.device_cls:hover {
  color: #fff !important;
  background-color: #3fb8bf;
}

::v-deep .el-tab-pane {
  display: flex;
  padding-left: 1.5625vw;
  flex-wrap: wrap;
  height: 8.5417vw;
  align-content: baseline;
}

::v-deep .el-tabs__content {
}

::-webkit-scrollbar-thumb {
  background-color: rgb(8, 86, 92);
  border-radius: 3px;
}

// 新版css

.group_1 {
  background-image: url(../assets/products/product_banner2.png);
  height: 24.9vw;
  border: 1px solid rgba(151, 151, 151, 1);
  width: 100vw;
}

.section_1 {
  width: 100vw;
  height: 24.9vw;
}

.text_10 {
  width: 6.67vw;
  height: 2.35vw;
  overflow-wrap: break-word;
  color: rgba(212, 222, 239, 1);
  font-size: 1.66vw;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 2.35vw;
  margin: 8.32vw 0 0 12.5vw;
}

.text_11 {
  width: 59.59vw;
  height: 3.29vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 2.39vw;
  font-family: AlibabaPuHuiTi-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 3.29vw;
  margin: 0.36vw 0 0 12.5vw;
}

.group_3 {
  background-color: rgba(255, 123, 52, 1);
  width: 9.38vw;
  height: 0.21vw;
  margin: 1.35vw 0 8.33vw 12.5vw;
}

.tabs_box {
  width: 100vw;
}
.tabs_soft {
  width: 75vw;
  margin: 1vw auto 0;
  display: flex;
  overflow: auto;
  .tab_item_box {
    margin-right: 2vw;
    cursor: pointer;
    .soft_item_box {
      padding: 0 0.5vw 1vw 0.5vw;
      color: #3439c0;
      border-bottom: 0.2vw solid #3439c0;
    }
    .no_selected {
      color: #000000;
      border-bottom: 0;
    }
    .soft_name {
      font-size: 0.83vw;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      line-height: 1.0417vw;
      margin-top: 0.8vw;
    }
  }
}

.sontype_list_box {
  display: flex;
  width: 75vw;
  margin: 1vw auto 0;
  flex-wrap: wrap;
  .sontype_item_box {
    height: 3.13vw;
    min-width: 10vw;
    padding: 0 0.5vw;
    margin: 0 1vw 2vw 0;
    font-size: 0.8333vw;
    color: #fff;
    background-color: rgba(52, 57, 192, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .no_selected {
    background-color: #edeff3;
    color: #222222;
  }
}
</style>
