<template>
  <div class="box">
    <div class="product_banner">
      <div class="content_box">
        <div class="content_left">
          <div class="product_title">
            {{ deviceInfo.name || "多功能空气质量传感器" }}
          </div>
          <div class="product_feature">
            {{
              deviceInfo.features ||
              "多合一监测｜高实用性｜易安装、接入灵活｜数据可视化"
            }}
          </div>
        </div>
        <div class="content_right">
          <div class="product_img_box">
            <img class="imgcls" :src="deviceInfo.lunImgs.value" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="pages_box">
      <div class="software_des">
        {{ deviceInfo.sysDes }}
      </div>
    </div>
    <div class="tech_box">
      <div class="title_box">
        <div class="tech_title">技术特点</div>
        <div class="tech_des">强大的研发实力，只要你需要，我们就可以。</div>
      </div>
      <div
        class="page_cls"
        v-for="(item, index) in deviceInfo.pageList"
        :key="index"
      >
        <div class="img_cls">
          <img :src="item.value" alt="" />
        </div>
        <div class="page_des_box">
          <div class="des_box">
            <div class="des_title">{{ item.title1 || "全能耗在线监测" }}</div>
            <div class="des_text">
              {{
                item.des1 ||
                "各类终端设备对建筑电、水、气等各类能耗实时上传至系统，实现能耗数据的采集、汇总及多维度分析。"
              }}
            </div>
          </div>
          <div class="des_box">
            <div class="des_title">{{ item.title2 || "可视化数据呈现" }}</div>
            <div class="des_text">
              {{
                item.des2 ||
                "采用饼图、线状图、柱状图、雷达图等多样化的图表形式，以可视化方式综合呈现能耗数据，方便用户从各种维度观察和分析数据。"
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="page_cls">
        <div class="img_cls">
          <img src="../assets/image/page2.png" alt="" />
        </div>
        <div class="page_des_box">
          <div class="des_box">
            <div class="des_title">多维度趋势分析</div>
            <div class="des_text">
              对能耗数据进行多维聚合分析，通过分项能耗、区域能耗、分户能耗的同比、环比分析，方便用户掌握能耗变化情况。
            </div>
          </div>
          <div class="des_box">
            <div class="des_title">能效指标分析评价</div>
            <div class="des_text">
              对重点能效指标进行计算分析，帮助用户识别能耗重点、挖掘节能潜力。
            </div>
          </div>
        </div>
      </div>
      <div class="page_cls">
        <div class="img_cls">
          <img src="../assets/image/page3.png" alt="" />
        </div>
        <div class="page_des_box">
          <div class="des_box">
            <div class="des_title">APP移动终端服务</div>
            <div class="des_text">
              拥有WEB端和APP移动端，充分发挥移动便携特性，打通线上线下服务体系，实现数据查询、事件实时提醒、事件处理登记等功能。
            </div>
          </div>
          <div class="des_box">
            <div class="des_title">能耗数据报表查询</div>
            <div class="des_text">
              依据用户的配置结果，提供抄表统计、分项统计、区域统计、分户统计和分时统计等各种维度的数据查询及报表导出服务。
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 悬浮 -->
    <!-- <div class="fix_box1">
      <div class="fix_text">
        <div class="fix_cls" @click="back()">
          <span>&lt;&nbsp; 返回</span>
        </div>
        <div
          class="fix_item"
          v-for="item in product_cate"
          :key="item.value"
          @click="backToProduct(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      product_cate: [
        {
          label: "软件产品",
          value: "nyszhjjfa",
        },
        {
          label: "硬件产品",
          value: "nywlwyjcp",
        },
      ],
      curdeviceVal: "",
      deviceInfo: {
        lunImgs: {},
      },
    };
  },
  mounted() {
    this.curdeviceVal = this.$route.params.deviceid;
    this.observeDoms(".feature_left");
    this.getDeviceInfo();
  },
  destroyed() {
    this.removeObserve(".feature_left");
  },
  methods: {
    async getDeviceInfo() {
      const res1 = await this.axios.get(
        "/jsondata/detaildata/" + this.curdeviceVal + ".json"
      );
      this.deviceInfo = res1.data;
    },
    backToProduct(value) {
      this.$router.push({
        name: "Products",
        params: {
          curFatherType: value,
          curSonType: value == "nyszhjjfa" ? "rjall" : "yjall",
        },
      });
    },
    back() {
      history.back();

      // this.$router.push({
      //   name: "Products",
      //   params: {
      //     cateid: value,
      //   },
      // });
    },
    removeObserve(selector) {
      let doms = document.querySelectorAll(selector);
      doms.forEach((dom) => {
        this.observer.unobserve(dom);
        this.observer.disconnent();
      });
    },
    observeDoms(selector) {
      let doms = document.querySelectorAll(selector);
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          entries[0].target.style.opacity = 1;
          entries[0].target.style.transition = "0.5s";
          entries[0].target.style.transform = "translateY(0px)";
        }
      });
      doms.forEach((dom) => {
        this.observer.observe(dom);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}

.product_banner {
  width: 100%;
  height: 28.64vw;
  background-image: url(../assets/image/test.png);
  background-size: cover;
  // margin-top: 15px;
  .content_box {
    display: flex;
    justify-content: space-between;
    height: 28.64vw;
    width: 70%;
    margin: auto;
    .content_left {
      margin-top: 11.19vw;
      .product_title {
        font-size: 2.08vw;
        font-weight: 400;
        color: #2d2d2d;
        line-height: 1.3;
        margin: 0 0 1.04vw 0;
      }
      .product_feature {
        font-size: 1.04vw;
        font-weight: normal;
        color: #666;
      }
    }
    .content_right {
      width: 44.27vw;
      height: 20.98vw;
      align-self: flex-end;
      overflow: hidden;
      position: relative;
      .imgcls {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.pages_box {
  width: 100%;
  // height: 61.04vw;
  background-color: #fff;
  // background-image: url(../assets/image/softwarebg.png);
  // background-size: 100% 100%;
  padding: 1.5625vw 0;
  box-sizing: border-box;
  .software_des {
    text-indent: 2em;
    width: 55%;
    margin: 0 auto;
    font-size: 0.9375vw;
    line-height: 190%;
    font-family: "Open Sans" !important;
    color: #666;
  }
}
// 技术特点
.tech_box {
  width: 100%;
  background-color: #fff;
  padding: 1.5625vw 0;
  box-sizing: border-box;
  .title_box {
    width: 62.5vw;
    margin: 0 auto;
    color: #333;
    .tech_title {
      font-size: 1.666vw;
    }
    .tech_des {
      margin-top: 0.41666vw;
    }
  }
}
.page_cls {
  width: 100%;
  background-color: #fff;
  padding: 3.64vw 0 5.2vw 0;
  .img_cls {
    width: 62.5vw;
    // max-height: 28.64vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // img {
    //   width: 100%;
    //   height: 100%;
    // }
  }

  // 描述
  .page_des_box {
    width: 62.5vw;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 4.1666vw;
    .des_box {
      width: 45%;
      .des_title {
        font-size: 1.25vw;
        font-weight: normal;
        margin-bottom: 0.729vw;
        color: #333;
      }
      .des_text {
        font-size: 0.9375vw;
        color: #999;
        line-height: 1.8;
      }
    }
  }
}
.page_cls:nth-child(2n + 1) {
  background-color: #f5f5f5;
}
</style>